.blank-app {
    
    /*display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    border: 1px solid rgba(0,0,0,0.2);
    */
    margin-top: 22px;
    padding: 20px 10px;  
    background: #fff;
    
    border-bottom: 0px;
    height: 94vh;
    overflow-x: hidden;
}

.blank-app .need-scroll{
    height: 83vh + 6.4 ; overflow-y:auto;   
}

.blank-app nav {
    -ms-flex: 0 0 300px;
    flex: 0 0 260px;
    padding: 1rem;
    border-right: 1px solid #c8ced3;
    height: 94vh  ; overflow-y:auto;
    background: #f0f0f0; //F0F0F0
}

.blank-app nav .btn-block {
    margin-bottom: 15px
}

.blank-app nav .nav {
    -ms-flex-direction: column;
    flex-direction: column
}

.blank-app nav .nav-item {
    position: relative;
    padding: 0px 0px 0px 0px;
    font-size: 14px;
    margin-bottom: 1px;
    font: 15.2px "Roboto", sans-serif !important;

}
.blank-app nav .nav-item:hover,.blank-app nav .nav-item.active {
    position: relative;
    background: #18A689;
    color: #ffffff

}
.blank-app nav .nav-item span:hover,.blank-app nav .nav-item.active a{ color: #fff}

.blank-app nav .nav-link {
    color: #23282c;
    border-bottom: 0px solid #c8ced3;

}
.blank-app nav .nav-link:hover{ cursor: pointer;}


.blank-app nav .nav-link .option {
    float: right;

    margin-left: 40px
}

/* END LEFT SIDE A*/



.blank-app .toolbar {
    padding-bottom: 0rem;
    border-bottom: 0px solid #c8ced3;
    padding: 0rem;
    padding-top: 1rem;
    padding-left: 1rem;
}

.blank-app .detail{
  padding: 1rem;
}

.blank-app .footer{
  padding: 5px 10px;
  background: #eee;
  border-top: 1px solid rgba(0,0,0,0.1);
  position: absolute; bottom: 0px;
  width: 100%;

}



@media(max-width:767.98px) {
    .blank-app {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .blank-app nav {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%
    }
}

@media(max-width:575.98px) {
    .blank-app .message .header {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }
    .blank-app .message .header .date {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%
    }
}
/* END EMAIL APP*/
