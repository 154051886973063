/*File Manager*/
.file-box {
  float: left;
  margin-bottom: 20px; }

.file-sec {
  border-left: 1px solid rgba(33, 33, 33, 0.05); }

.file-manager {
  list-style: none outside none;
  margin: 0;
  padding: 0; }
  .file-manager h5 {
    text-transform: uppercase; }

.folder-list li {
  display: block; }
  .folder-list li.active a {
    background: rgba(33, 33, 33, 0.05); }
  .folder-list li a {
    display: block;
    padding: 10px 15px;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease; }
    .folder-list li a:hover {
      background: rgba(33, 33, 33, 0.05); }
  .folder-list li i {
    margin-right: 8px;
    color: #878787; }

.category-list li {
  display: block; }
  .category-list li a {
    color: #666666;
    display: block;
    padding: 5px 0; }
  .category-list li i {
    margin-right: 8px;
    color: #3d4d5d; }

.file-manager h5.tag-title {
  margin-top: 20px; }

.tag-list li {
  float: left; }
  .tag-list li a {
    font-size: 12px;
    background-color: #dedede;
    padding: 5px 12px;
    border: 1px solid #dedede;
    margin-right: 5px;
    margin-top: 5px;
    display: block; }

.file-manager .hr-line-dashed {
  margin: 15px 0; }

.file {
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 2px;
  padding: 0;
  background-color: #E4BA75;
  position: relative;
}
.file-store{
  background-color: #C2C6BE
}
.file:hover{ border:1px solid rgba(0,0,0,0.3)}

.file .icon, .file .image, .file .block {
    height: 100px;
    line-height: 22px;
    overflow: hidden;
    background-size: cover;
    background-position: top;
}
.file .block{
  padding: 10px;
}
.file .icon {
    padding: 15px 10px;
    display: table;
    width: 100%;
    text-align: center;
}
.file .icon i {
    color: #667add;
    display: table-cell;
    font-size: 30px;
    vertical-align: middle; }
  .file .file-name {
    padding: 10px;
    background-color: rgba(33, 33, 33, 0.1);
    border-top: 1px solid rgba(33, 33, 33, 0.05); }
    .file .file-name span {
      font-size: 12px;
      color: rgba(0,0,0,0.5); }

.file-control {
  font-size: 13px;
  margin-right: 10px;
  text-transform: uppercase; }
  .file-control.active {
    color: #f33923; }

.file-name small {
  color: #878787; }
