// Here you can add other styles

/*@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=vietnamese');*/
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');


/*GENERGAL */
body{
  font: 14px "Roboto", sans-serif !important;
  overflow-y: hidden !important;
}

/* COLOR*/
.bg-grey-2{ background-color: #9E9E9E !important;}
.bg-green{ background: #18A689 !important }
.txt-green{ color: #18A689 !important}

.bg-red{ background: #FF3737 !important }
.txt-red{ color: #FF3737 !important}

.bg-organge{ background: #FF6600 !important }
.txt-organge{ color: #FF6600 !important}

.bg-dark{ background: #606060 !important }
.txt-dark{ color: #303030 !important}

.bg-white{ background: #ffffff !important }
.txt-white{ color: #ffffff !important}

.bg-grey{ background: #F4F4F4 !important}

// SỔ TIỀN 
.finalcial-pc,.finalcial-ck{ color: #FF3737 }
.finalcial-pt,.finalcial-tm{ color: #18A689}

/* TEXT */
.txt-bold{ font-weight: 500}

/* END TEXT*/


/* END COLOR */
.disabled{ display: none}
.myBadge{ padding: 4px 7px !important; border-radius: 9px !important;}

/* SUGGEST HOLDER*/
.suggest-holder,.suggest-holder-2{
    margin: 0px; padding: 0px; border: 1px solid rgba(0,0,0,0.1);background: #fff; width: 100%; position: absolute; border-top: 0px; z-index: 2000;
    display: none;
    max-height: 500px; overflow-y: auto; overflow-x: hidden;
}
.suggest-holder-2{ position: absolute; width: 100%; display: block;}
.suggest-holder li,.suggest-holder-2 li{ list-style: none; display: block; padding: 10px; border-bottom: 1px solid rgba(0,0,0,0.1); cursor: pointer;}

.suggest-holder li:hover,.suggest-holder li.active{ background: rgba(0,0,0,0.1);}

/* END SUGGEST HOLDER */

/*nav-list*/
ul.nav-list{ 
  margin: 0; padding:0;
  margin-top:20px
}
ul.nav-list li{ 
  list-style: none;
  padding: 10px 5px; 
  border-bottom : 0px solid rgba(0,0,0,0.1);
  margin-bottom: 1px;
  
}
ul.nav-list li:hover,ul.nav-list li.active{
  background: rgba(0,0,0,0.1)
}

ul.nav-list li span{ font-weight: 500}
ul.nav-list li a{  margin: 0px 6px; cursor: pointer}
ul.nav-list li a:hover{
  color: #18A689 !important;
}


/* LIST INLINE - ASDASD-ASDASD */
.list-inline{ padding: 20px;}

.list-inline span{ margin-right: 24px; font-size: 14px; cursor: pointer}
.list-inline span:hover,.list-inline span.selected{ color: #18A588}

/*NAV-LIST*/
.N2T::first-letter{
  text-transform: capitalize !important;
  font-style: italic !important;
}


/* filter div holder*/
.filter-holder{
  margin: 0px; padding: 20px; border: 1px solid rgba(0,0,0,0.1);background: #fff;  position: absolute;  z-index: 1;
  width: 300px; 
  text-align:left;
  left: -300px;
  max-height: 720px; overflow-y: auto; overflow-x: hidden;
  -webkit-box-shadow: 6px 8px 6px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 6px 8px 6px -5px rgba(0,0,0,0.75);
  box-shadow: 6px 8px 6px -5px rgba(0,0,0,0.75);


}
/* end filter div*/

/* FORM */
.row-form{ margin-bottom: 20px !important }
/* END FORM*/

.input-datepicker,.input-datepicker-start,.input-datepicker-end{
  padding: 8.1px; border: 1px solid #E4E7EA;
  border-right: 0px;
  width: 120px; color:#666;
  font-size: 14px;
}
.input-datepicker-end{
  border: 1px solid #E4E7EA;
  margin-right: 10px;
}
.input-datepicker{ width: auto; border: 1px solid #E4E7EA;}


.btn-trio{
  background:#CF9D48 ;
  border:1px solid #CF9D48;
  color:#fff
}
.btn-trio:hover{
  background: #666;
  border:1px solid #666;
  color: #fff
}
.mr-5{ margin-right: 5px !important;}
.nav-title,.badge,.font-weight-bold, strong,b,.switch-label{ font-weight: 500 !important}
.badge{ padding-top: 5px;}

label{ font-weight: 500 !important; font-size: 14px;}
.font-weight-bold{ font-weight: 500}

/*.ButtonGroup-select{}*/

.ButtonGroup-select Button{ background-color: #7696A6 !important; color: #fff; border: 0px;}
.ButtonGroup-select Button.selected,.ButtonGroup-select Button:hover{ background-color: #40525B !important; color: #fff; border: 0px;}

.form-general{ padding: 60px}
.form-general .row{ padding: 20px ; padding-left: 0px; border-bottom: 1px solid #ddd; }
.form-general .row p{ font-weight: 500}


/*DIV CROLL*/
.div-croll{
  height: 83vh + 6.4 ;
  overflow-y:hidden;
  border-bottom: 0px;
  overflow-x: hidden;
}

.div-main{
  margin-top: 20px;
  padding: 20px;
  height: 89vh + 0.4;
  overflow: auto;
}
.div-form{
  padding: 30px;
  border: 1px solid #ddd;
  background: #f5f5f5
}


/* BENJAMIN BUTTON */
.btn-round-sm{
  width: 24px; height: 24px;
  border-radius: 50%;
  border: 0px;
}
/* END BUTTON */

/* WYSIWYD */
.rdw-storybook-root {
  margin: 50px !important;
  width: 90% !important;
}
.rdw-storybook-editor {
  border: 1px solid #dddddd !important;
  padding: 5px !important;
  border-radius: 2px !important;


}
.rdw-storybook-toolbar{
  top: -130px !important;
}
.rdw-storybook-textarea{
  margin-top: 0px;
  resize: none;
  padding: 10px !important;
  width: 100%;
  border: 1px solid #dddddd;
  height: 320px !important;
}
.toolbar-class{
  border: 0px solid #000 !important
}

/* WYSIWYD */


/* GUIDE BOOK */

.guidebook{
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 24px;
  padding: 30px;
  padding-bottom: 90px ;
  color: #666666;

  height: 88vh !important;
  overflow-y: auto !important

}
.guidebook p{
  padding: 0px; margin: 0px;
  padding-top: 3px;
  padding-bottom: 3px;


}
/*END GUIDE BOOK*/

/* APP HEADER*/


/*BREADCRUM*/
.breadcrumb{ background: #F0F3F5;}
.breadcrumb-item{ font-weight: 500 !important;}

/* NAV NAV-TABS*/
.nav-tabs li a{ height: 50px !important; line-height:32px !important}
.nav-tabs li a.active{ font-weight: 500 !important}

/* TABLE*/
/*.product-board{}*/
thead tr th{ border: 0px !important;}
tr th{ font-size: 15px !important; font-weight: 500}
.product-board tr td{ font-size: 12px !important; vertical-align: middle;}

table.vk-table{
  
  display: grid;
  overflow-x: auto !important;
  /*overflow-y: auto !important;*/
  
  border-bottom: 0px solid #eeeeee;
  

}

table.vk-table tbody {
    /*display:block;

    overflow-x: auto !important;*/
    //display: grid;
    overflow-x: auto !important;
    overflow-y: auto !important;
    border-left: 0px solid #ddd;
    border-right: 0px solid #ddd;
    border-bottom: 0px;


}

table.vk-table thead,table.vk-table tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}

table.vk-table thead{
  /*background-color: #455361;
  color:#fff; 
  border: 0px;*/
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;

}

table.vk-table thead tr th{
  text-transform: uppercase;
  font-size: 12px !important;
  color: #18A689 !important
}



.vk-table tbody tr:hover{ background:#F5F6F7 !important;}

.vk-table > tbody > tr:nth-child(2n+2)  {
   background-color: #eee;
}
/*.vk-table > tbody{ border: 1px solid #ddd !important}*/
.vk-table > tbody > tr >td{
    border-left: 0px solid #eee !important;
    /*border-top: 1px solid #ddd !important;*/
    vertical-align: middle;
    border-top: 0px solid #eee !important; 

    border-bottom: 1px solid #dddddd !important;


}
.vk-table > tbody > tr >td:last-of-type{ border-right: 0px solid #ddd !important; }

.vk-table > tfoot{
  background-color: #fff; 
}
.vk-table >tfoot > tr >td{
  border-top: 1px solid #dddddd !important;
  
}


/* SPIN LOADING*/

.preloader-it{
  width: 100%; overflow-y: auto; height: 100%; background: rgba(0,0,0,0.7);
  position: fixed; z-index: 2000; bottom: 0px;

}

.preloader-it > .preload-text{
  width: 300px;
  text-align: center;
  position: absolute;

  left: 44%; font-size: 20px;
  top:36%; color: #fff;
}

.lds-spinner {

  color: official;
  display: inline-block;

  position: absolute;
  top:41%;
  left: 50%;
  width: 64px;
  height: 64px;
}
.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* END SPIN LOAD*/


/*
MODAL
*/

a.btn-delete{ cursor: pointer;}
a.btn-delete:hover{ color:#DC3545 !important}

.custom-modal{
  max-width: 100% !important;
}
.modal-dialog{ border: 1px solid rgba(0,0,0,0.1) !important; width: 100% !important}
.modal-lg{ width: 100% !important}
.modal-header{
  height: 50px !important; border-bottom: 1px solid rgba(0,0,0,0.1); background: #F0F3F5;
}
.modal-header h5.modal-title{ height: 50px !important; line-height: 16px; font-size: 15px !important; }
.modal-header button{ height: 50px !important; line-height: 16px;}
.modal-body{ font-size: 16px !important}
.my-modal-footer{ padding: 1rem; border-top: 1px solid rgba(6,6,6,0.1); border-bottom-right-radius: 9px !important;

  border-bottom-left-radius: 9px !important;
}
.modal-err{
  padding: 5px 10px;
  background: #F0F3F5;

  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom-right-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}
.modal-body{
  padding: 30px;  
}


/* DATA GRID*/

/* btn-datagrid*/
.btn-datagrid{ background: transparent; background-color: #eee;  border: 1px solid rgba(0,0,0,0.1) ;  color: #455361}
.btn-datagrid:hover{ background: #455361; color: #fff; border: 1px solid rgba(0,0,0,0.1)}

.ag-theme-material .ag-footer{
  padding-top: 12px;
}
.ag-footer i{ padding:0 6px 0 6px }
.ag-footer span.info{ color:#455361; font-weight: 500; padding-top: 12px; margin-left: 12px;}




.ag-theme-material .ag-icon-checkbox-checked {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDBIMmEyIDIgMCAwIDAtMiAydjE0YTIgMiAwIDAgMCAyIDJoMTRhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJ6TTcgMTRMMiA5bDEuNDEtMS40MUw3IDExLjE3bDcuNTktNy41OUwxNiA1bC05IDl6IiBmaWxsPSIjMzMzIi8+PC9zdmc+) !important;
}
.ag-theme-material .ag-header {
  color: #fff !important;
  font: 500 12px "Roboto", sans-serif !important;
  background: #455361 !important;
  text-transform: uppercase !important;
}

.ag-theme-material .ag-header-cell {

  border-left: 1px solid rgba(0,0,0,0.3) !important;
  padding-left: 15px !important

}

.ag-theme-material .ag-header-row {

  border-style: solid !important;
  border-color: rgba(0,0,0,0.1) !important;
  box-sizing: border-box;
  border-width: 0px !important;
}


.ag-theme-material .ag-row {
  border-style: solid;
  border-color: #ddd !important;
  box-sizing: border-box;
  border-width: 0 !important;
  border-bottom-width: 1px !important;

}
.ag-theme-material .ag-row:nth-child(2n+2){
  background-color: #f0f0f0 ;
}


.ag-theme-material .ag-cell {

  line-height: 46px;

  font-size: 14px !important;

  border-left: 0px solid rgba(0,0,0,0.1) !important;

  padding-left: 15px !important;
  padding-right: 15px !important;

}


.ag-row-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    background-color: #F5F6F7 !important;
}

/*.ag-column-hover {
    background-color: #dfffdf;
}*/
/* END DATA GRID*/


/* Admin Elmer */
a:hover, a:active {
  outline: 0 !important;
}
/****************************/
/** Basic **/
/****************************/


.pointer{ cursor: pointer; color: red}
.pa-0 {
  padding: 0px !important; }

.pa-5 {
  padding: 5px !important; }

.pa-10 {
  padding: 10px !important; }

.pa-15 {
  padding: 15px !important; }

.pa-20 {
  padding: 20px !important; }

.pa-25 {
  padding: 25px !important; }

.pa-30 {
  padding: 30px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pr-50 {
  padding-right: 50px !important; }

.ma-0 {
  margin: 0px !important; }

.ma-5 {
  margin: 5px !important; }

.ma-10 {
  margin: 10px !important; }

.ma-15 {
  margin: 15px !important; }

.ma-20 {
  margin: 20px !important; }

.ma-25 {
  margin: 25px !important; }

.ma-30 {
  margin: 30px !important; }

.mt-0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.ml-0 {
  margin-left: 0px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-45 {
  margin-left: 45px !important; }

.ml-50 {
  margin-left: 50px !important; }

.ml-55 {
  margin-left: 55px !important; }

.ml-60 {
  margin-left: 60px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mr-60 {
  margin-right: 60px !important; }

.ml-auto {
  margin-left: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.no-float {
  float: none !important; }

.overflow-hide {
  overflow: hidden !important; }

.clearfix {
  overflow: hidden;
  clear: both;
  float: none;
}

.hidden{ display: none}
.block{ display: block;}
.form-err{ color: '#AA001F' !important ; font-weight: 500;}


  .font-10 {
    font-size: 10px !important; }

  .font-11 {
    font-size: 11px !important; }

  .font-12 {
    font-size: 12px !important; }

  .font-13 {
    font-size: 13px !important; }

  .font-14 {
      font-size: 14px !important; }

  .font-15 {
    font-size: 15px !important; }

  .font-16 {
    font-size: 16px !important; }

  .font-18 {
    font-size: 18px !important; }

  .font-20 {
    font-size: 20px !important; }

  .font-21 {
    font-size: 21px !important; }

  .font-22 {
    font-size: 22px !important; }

  .font-24 {
    font-size: 24px !important; }

  .weight-500 {
    font-weight: 500 !important; }
/* END Admin Elmer*/

/* HONG HAI ADDED*/
ul.playlist{
  margin: 0px;
  padding: 0px;
}
ul.playlist li:first-child{ border-top: 0px;}
ul.playlist li{
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  border-top: 1px solid #f9f9f9;
}
ul.playlist li a{ color: #18A689 !important; font-weight: 500;}
ul.playlist li a:hover, ul.playlist li a.active{ color: #F86C6B !important;}
ul.playlist li:last-child{ border-bottom: 0px;}
